.react-datepicker-popper {
    z-index: 100 !important
}

@font-face {
    font-family: "Akkurat";
    font-weight: normal;
    font-style: normal;
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.eot?#iefix") format("embedded-opentype"),
    url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-regular.woff") format("woff");
}

@font-face {
    font-family: "Akkurat";
    font-weight: bold;
    font-style: normal;
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.eot?#iefix") format("embedded-opentype"),
    url("https://frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-bold.woff") format("woff");
}

@font-face {
    font-family: "Akkurat";
    font-weight: normal;
    font-style: italic;
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot");
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.eot?#iefix") format("embedded-opentype"),
    url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-Italic.woff") format("woff");
}

@font-face {
    font-family: "Akkurat";
    font-weight: bold;
    font-style: italic;
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot");
    src:url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot?#iefix") format("embedded-opentype"),
    url("//frontend-components-assets.dimu.org/fonts/Akkurat/fonts/lineto-akkurat-pro-boldItalic.woff") format("woff");
}

@font-face {
    font-family: "Akkurat Mono";
    src:url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.eot");
    src:url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.eot?#iefix") format("embedded-opentype"),
    url("https://frontend-components-assets.dimu.org/fonts/AkkuratMono/fonts/lineto-akkuratmono-pro-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
